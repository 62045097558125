<template>
  <div>
    <b-select
      :name="name"
      v-model="codeState"
      :state="validState"
      >
        <option value="">{{ $t('Chose your province') }}</option>
        <option v-for="option in available_regions" :value="option.code" :key="option.code">
          {{ option.name }}
        </option>
    </b-select>
    <b-form-invalid-feedback >
      <!-- This will only be shown if the preceeding input has an invalid state -->
      <div v-for="(validation, index) in validationsCondition"
        :key="index">
        {{ validation.text }}
      </div>
    </b-form-invalid-feedback>
  </div>
</template>

<script>
import filter from 'lodash/filter'
import find from 'lodash/find'
import Config from '@/config'

export default {
  $_veeValidate: {
    value () {
      return this.value
    },
    name () {
      return this.name
    }
  },
  props: {
    name: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: String,
      default: () => ''
    },
    countryId: {
      type: String,
      default: () => Config.Theme.default.country
    },
    validations: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  mounted () {
    this.$store.dispatch('checkout/loadCountries')
  },
  computed: {
    validationsCondition () {
      return filter(this.validations, { condition: true })
    },
    available_regions () {
      if (this.$store.state.checkout.countries.length > 0) {
        const countryFound = find(this.$store.state.checkout.countries, { 'id': this.countryId })
        return countryFound ? countryFound.available_regions : []
      }
      return []
    },
    codeState: {
      get () {
        return this.value
      },
      set (newState) {
        this.$emit('input', newState)
      }
    },
    validState () {
      return find(this.validations, { condition: true }) ? false : null
    }
  }
}
</script>
