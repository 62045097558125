<template>
  <b-row>
    <b-col class="text-left" cols="8" sm="5" lg="3">
      <address class="address_info">
        <strong>{{ fullname }}</strong><br>
        {{ street }} - {{ address.city }}<br>
        {{ address.postcode }} {{ address.region.region_code }} ( {{ address.country_id }})<br>
        <div><i class="material-icons">phone</i>{{ mobilePhone }}</div>
      </address>
      <base-check-box v-if="address.default_shipping === true"
        name="shipping_dafault"
        :label="$t('Shipping Default')"
        :value="address.default_shipping"
        :disabled= true
      >
      </base-check-box>
      <div class="address_actions">
        <b-button variant="primary" @click="showModal">{{ $t('Edit') }}</b-button>
        <b-button variant="primary" class="ml-3" @click="showModalConfirm">{{ $t('Remove') }}</b-button>
      </div>
      <hr>
    </b-col>
  </b-row>
</template>

<script>
import BaseInput from '@/components/Form/BaseInput'
import BaseCheckBox from '@/components/Form/BaseCheckBox'
import join from 'lodash/join'

export default {
  props: {
    address: {
      type: Object,
      default: null
    }
  },
  computed: {
    fullname () {
      return this.address.firstname + ' ' + this.address.lastname
    },
    street () {
      return join(this.address.street, ', ')
    },
    mobilePhone () {
      return this.address.telephone ? this.address.telephone : 'N/A'
    }
  },
  methods: {
    showModal () {
      this.$emit('edit')
    },
    showModalConfirm () {
      this.$emit('remove')
    }
  },
  components: {
    BaseInput,
    BaseCheckBox
  }
}
</script>

<style lang="scss">
.address_info {
  margin-bottom: 5px;
  i {
    vertical-align: sub;
    font-size: 18px;
  }
}
</style>
