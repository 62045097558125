<template>
  <b-modal :hide-header="true" :hide-footer="true" v-model="ModalShowComputed">
    <div class="container-body text-center">
    <p class="" v-html="message"></p>
      <b-button class="btn btn-outline-black" @click="closeModal">{{ $t('Cancel') }}</b-button>
      <b-button variant="primary" class="ml-3" @click="confirm">{{ $t('Confirm') }}</b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    ModalShow: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: null
    },
    message: String
  },
  computed: {
    ModalShowComputed: {
      get () {
        return this.ModalShow
      },
      set (newValue) {
        this.$emit('updateModalConfirm', newValue)
      }
    }
  },
  methods: {
    closeModal () {
      this.$emit('cancel', false)
    },
    confirm () {
      this.$emit('confirm', this.options)
    }
  }
}
</script>
